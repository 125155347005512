<template>
    <div>
        <div class="grid-content bg-purple">
            <div class="handle-box">
                <el-button type="success" icon="el-icon-refresh" @click="init">刷新</el-button>
            </div>
            <el-table :data="tableData" style="width: 100%;margin-bottom: 20px;" row-key="id" border
                      highlight-current-row
                      :tree-props=" {  children: 'subs', hasChildren: 'hc' }">
                <el-table-column prop="id" label="菜单ID" sortable width="150"></el-table-column>
                <el-table-column prop="title" label="名称" width="180"></el-table-column>
                <el-table-column label="类型" width="180">
                    <template #default="scope">
                        {{TYPES[scope.row.data.type].name }}
                    </template>
                </el-table-column>
                <el-table-column prop="icon" label="图标">
                    <template #default="scope">
                        <i :class="scope.row.icon"></i>
                    </template>
                </el-table-column>
                <el-table-column prop="data.url" label="地址"></el-table-column>
                <el-table-column prop="data.orderNum" label="顺序"></el-table-column>
                <el-table-column label="操作" conter width="250">
                    <template #default="scope">
                        <el-button type="text" icon="el-icon-plus" @click="handel_add(scope.row)">添加</el-button>
                        <el-button type="text" icon="el-icon-edit" @click="handel_info(scope.row)">编辑</el-button>
                        <el-button type="text" icon="el-icon-delete" class="red"
                                   @click="handle_del(scope.$index, scope.row)">删除
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>

            <el-dialog title="导航信息" :close-on-click-modal="false" width="600px" v-model="digShow">
                <el-form ref="form" :model="cuttData" :rules="rules">
                    <el-form-item label="父节点" label-width="100px">
                        <el-input v-model="cuttData.parentName" disabled style="width: 80%"></el-input>
                        <el-button icon="el-icon-delete" class="red" @click="handel_clearParent">删除</el-button>
                    </el-form-item>
                    <el-form-item label="名称" label-width="100px" prop="name">
                        <el-input v-model="cuttData.name"></el-input>
                    </el-form-item>
                    <el-form-item label="图标" label-width="100px" prop="icon">
                        <el-input v-model="cuttData.icon"></el-input>
                    </el-form-item>
                    <el-form-item label="类型" label-width="100px" prop="type">
                        <el-select v-model="cuttData.type" placeholder="请选择角色类型" width="100%">
                            <el-option v-for="item in TYPES" :key="item.value" :label="item.name" :value="item.value"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="序号" label-width="100px" prop="orderNum">
                        <el-input v-model="cuttData.orderNum"></el-input>
                    </el-form-item>
                    <el-form-item label="请求地址" label-width="100px" prop="path">
                        <el-input v-model="cuttData.path"></el-input>
                    </el-form-item>
                    <el-form-item label="web地址" label-width="100px" prop="url">
                        <el-input v-model="cuttData.url"></el-input>
                    </el-form-item>
                    <el-form-item label="权限标记" label-width="100px" prop="perms">
                        <el-input v-model="cuttData.perms"></el-input>
                    </el-form-item>
                </el-form>
                <div slot="footer" class="dialog-footer">
                    <el-button @click="digShow = false">取 消</el-button>
                    <el-button type="primary" @click="handel_validate('form')" :disabled="lock">确 定</el-button>
                </div>
            </el-dialog>
        </div>
    </div>
</template>

<script>
    import menuApi from '../../api/base/api_menu'

    export default {
        name: 'aclmanage',
        data() {
            return {
                lock:false,
                digShow: false,
                tableData: [],
                cuttData: {
                    parentName: undefined,
                    menuId: undefined,
                    parentId: undefined,
                    name: undefined,
                    path: undefined,
                    url: undefined,
                    perms: undefined,
                    type: undefined,
                    icon: undefined,
                    orderNum: undefined
                },
                TYPES: { "-1":{ name: '禁用', value: -1},"0":{name: '目录', value: 0}, "1":{name: '菜单', value: 1},"2": {name: '按钮', value: 2}},
                rules: {
                    name: [
                        {required: true, message: '请输入菜单名称', trigger: 'blur'},
                        {min: 2, max: 15, message: '长度在 2 到 15 个字符', trigger: 'blur'}
                    ],
                    // path: [ {  message: '请输入正确的路径', pattern: /^[a-z+A-Z+0-9+]{3,15}$/, trigger: 'blur'}],
                    type: [{required: true, message: '请选择菜单类型', trigger: 'blur'}],
                    url: [{required: true, message: '请设置url', trigger: 'blur'}]
                }
            }
        },
        mounted() {
            this.init()
        },
        methods: {
            init() {
                this.init_data();
            },
            init_data() {
                menuApi.sget().then(res => { this.tableData =   res.code === 200?  res.data.subs:[];})
            },
            //校验数据
            handel_validate(ref) {
                this.$refs[ref].validate((valid) => {
                    if (valid) {
                        this.hande_save();
                    } else {
                        return false;
                    }
                });
            },
            //保存数据
            async hande_save() {
                if(this.lock){return ;}this.lock=true;
                this.cuttData.add? menuApi.add(this.cuttData).then(this.res_handel): menuApi.update(this.cuttData).then(this.res_handel);
            },
            //清除父级节点
            handel_clearParent() {
                this.cuttData.parentId = 0;
                this.cuttData.parentName = undefined;
            },
            //设置添加对话框
            handel_add(row) {
                this.cuttData = {add: true, parentName: undefined, menuId: undefined, parentId: undefined};
                if (row) {
                    this.cuttData.parentId = row.data.menuId;
                    this.cuttData.parentName = row.data.name;
                }
                this.digShow = true;
            },
            //设置修改、添加
            handel_info(row) {
                this.cuttData = {...row.data};
                this.digShow = true;
            },
            //结果请求设置
            res_handel(res) {
                this.digShow=this.lock=false;
                if (200 === res.code) {
                    this.$message.success("操作成功！");
                    this.init_data();
                } else {
                    this.$message.error(res.msg);
                }
            },
            //删除
            handle_del(index, row) {
                this.$confirm('此操作将删除[' + row.title + '] 菜单, 是否继续?', '提示', {  confirmButtonText: '确定', cancelButtonText: '取消', type: 'warning' }).then(() => {
                    menuApi.delete(row.data).then(this.res_handel);
                }).catch(() => {});
            },

        }
    }
</script>
<style scoped>  .red {
    color: red
}</style>
